import React, { useState, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Slider } from 'primereact/slider';
import { FlexBox, Text } from '@eltoro-ui/components';
import {
  BANNER_CPM,
  VIDEO_CPM,
  MIN_IMPRESSIONS_COUNT,
  BANNER_FREQ,
  VIDEO_FREQ,
} from 'Utils/constants';
import './CostItem.scss';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';

interface Props {
  type: string;
  minDays: number;
  prospectsCount: number;
  minProspCount: number;
  defaultCost: number;
  days: number;
  setCreativesValid: Dispatch<
    SetStateAction<{
      banner: boolean;
      video: boolean;
    }>
  >;
  handleUpdateMutableData: (updatedCost: number, type: string, frequency: number) => void;
}

const CostItem = ({
  type,
  prospectsCount,
  days,
  defaultCost,
  minDays,
  setCreativesValid,
  minProspCount,
  handleUpdateMutableData,
}: Props) => {
  const CPM = type === 'banner' ? BANNER_CPM : VIDEO_CPM;
  const [currentCostPercent, setCurrentCostPercent] = useState(100);
  const [currentFrequency, setCurrentFrequency] = useState(
    (1000 * defaultCost) / (CPM * days * prospectsCount)
  );

  const [minCost, setMinCost] = useState(0);
  const [maxCost, setMaxCost] = useState(0);
  const [price, setPrice] = useState(defaultCost);

  useEffect(() => {
    if (price < minCost) {
      setCurrentCostPercent(0);
    } else if (price > maxCost) {
      setCurrentCostPercent(100);
    } else {
      setCurrentCostPercent((+price * 100) / maxCost);
    }
  }, [price, minCost, maxCost]);

  const handleUpdateCost = (updatedCost: number) => {
    let frequency = (1000 * updatedCost) / (CPM * days * prospectsCount);

    if (type === 'banner') {
      if (frequency > BANNER_FREQ) {
        frequency = BANNER_FREQ;
      }
    }

    if (type === 'video') {
      if (frequency > VIDEO_FREQ) {
        frequency = VIDEO_FREQ;
      }
    }

    setCurrentFrequency(frequency);
    handleUpdateMutableData(updatedCost, type, frequency);
  };

  useEffect(() => {
    let MIN_FREQ = 1;

    if (MIN_IMPRESSIONS_COUNT / (prospectsCount * days) > 1) {
      MIN_FREQ = MIN_IMPRESSIONS_COUNT / (prospectsCount * days);
    }

    const minImp = MIN_FREQ * prospectsCount * days;

    if (days >= minDays && prospectsCount >= minProspCount) {
      if (minImp > MIN_IMPRESSIONS_COUNT) {
        const min = +((CPM / 1000) * minImp).toFixed(2);
        setMinCost(min);
      } else {
        const min = +((CPM / 1000) * MIN_IMPRESSIONS_COUNT).toFixed(2);
        setMinCost(min);
      }
    }
  }, [days, prospectsCount, minDays, minProspCount]);

  useEffect(() => {
    const freq = type === 'banner' ? BANNER_FREQ : VIDEO_FREQ;
    const maxImpression = freq * prospectsCount * days;
    const res = (CPM / 1000) * maxImpression;

    if (days >= minDays && prospectsCount >= minProspCount) {
      setMaxCost(+res.toFixed(2));
    }
  }, [days, prospectsCount, minDays, minProspCount]);

  const hasError = useMemo(() => {
    return price < minCost || price > maxCost;
  }, [price, minCost, maxCost]);

  useEffect(() => {
    setCreativesValid(prev => ({
      ...prev,
      [type]: !hasError,
    }));
  }, [hasError]);

  const handleChange = (e: InputNumberChangeEvent) => {
    const updatedVal = e.value ?? 0;
    const currentCostPercent = (updatedVal * 100) / maxCost;
    setPrice(updatedVal);

    const updatedCost = +((currentCostPercent * maxCost) / 100).toFixed(2);
    handleUpdateCost(updatedCost);
  };

  const onSliderChange = (e: any) => {
    setCurrentCostPercent(+e.value);
    setPrice(+((+e.value * maxCost) / 100).toFixed(2));
    const updatedCost = +((e.value * maxCost) / 100).toFixed(2);
    handleUpdateCost(updatedCost);
  };

  useEffect(() => {
    let price = (CPM / 1000) * (currentFrequency * days * prospectsCount);

    setPrice(+price.toFixed(2));
    handleUpdateMutableData(price, type, currentFrequency);
  }, [days, prospectsCount, type]);

  const isDisabled = minCost === maxCost;

  return (
    <FlexBox
      UNSAFE_className="cost_item_wrapper"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <FlexBox
        UNSAFE_className="heading"
        justifyContent="space-between"
        alignItems="center"
        padding="0.75rem 1.5rem"
      >
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 16, textTransform: 'capitalize' }}
        >
          {type} Ad
        </Text>
        <Text
          on="white"
          weight="bold"
          textAlign="left"
          UNSAFE_style={{ color: '#2B1F0A', fontSize: 16 }}
        >
          $ {price.toFixed(2)}
        </Text>
      </FlexBox>
      <FlexBox
        UNSAFE_className="actions-wrapper"
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 1.5rem"
      >
        <FlexBox
          UNSAFE_className="manual_input_wrapper"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Text
            on="white"
            textAlign="left"
            UNSAFE_style={{ color: '#2B1F0A', fontSize: 14, marginBottom: '8px' }}
          >
            Cost
          </Text>
          <InputNumber
            inputId="currency-us"
            value={price || null}
            maxFractionDigits={2}
            minFractionDigits={0}
            onChange={handleChange}
            useGrouping={false}
            mode="currency"
            currency="USD"
            inputClassName={`manual_input ${hasError ? 'error_input' : ''}`}
          />
          {hasError && (
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{ color: '#FF2D38', fontSize: 14, marginTop: '8px' }}
            >
              {price < minCost ? `Min is ${minCost.toFixed(2)}` : `Max is ${maxCost.toFixed(2)}`}
            </Text>
          )}
        </FlexBox>
        <FlexBox
          UNSAFE_className={`slider_input_wrapper ${isDisabled ? 'disabled_slider' : ''}`}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Slider
            className="cost_slider"
            value={currentCostPercent}
            min={(minCost * 100) / maxCost}
            max={100}
            disabled={isDisabled}
            onChange={onSliderChange}
          />
          <FlexBox
            UNSAFE_className="slider_input_wrapper"
            justifyContent="space-between"
            alignItems="center"
            UNSAFE_style={{ width: '100%', paddingTop: '0.5rem' }}
          >
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{
                color: isDisabled ? '#757575' : '#2B1F0A',
                fontSize: 14,
                marginBottom: '8px',
              }}
            >
              ${minCost.toFixed(2)}
            </Text>
            <Text
              on="white"
              textAlign="left"
              UNSAFE_style={{
                color: isDisabled ? '#757575' : '#2B1F0A',
                fontSize: 14,
                marginBottom: '8px',
              }}
            >
              ${maxCost.toFixed(2)}
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CostItem;
